import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SettingsReactorComponent } from '~/settings/settings.reactor.component';

@Component({
	selector: 'app-root',
	template: `<app-settings-reactor></app-settings-reactor><router-outlet></router-outlet>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterModule, SettingsReactorComponent],
	host: {
		class: 'block w-100 h-100',
	},
})
export class RootPageComponent {}
