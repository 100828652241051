import { Injectable } from '@angular/core';
import { BaseApiService } from '~/core/services/api/base.api.service';
import { SettingsResponse } from '~interfaces/settings';
import { ClientSettings } from './settings.type';

@Injectable({ providedIn: 'root' })
export class SettingsApiService extends BaseApiService {
	protected readonly commonPath = ['settings'];

	public get(): Promise<ClientSettings> {
		const params = { t: this.paramTime };
		return this.http.get<SettingsResponse>(this.url(), params).then(parseSettingsFromServer);
	}
}

const parseSettingsFromServer = (response: SettingsResponse): ClientSettings => {
	const { theme } = response.general;
	return {
		theme,
	};
};
