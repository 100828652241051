import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class InitializationService {
	private _initialized = false;

	public get initialized(): boolean {
		return this._initialized;
	}

	public setState(newState: boolean): void {
		this._initialized = newState;
	}
}
