import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { navigateToCurrentUrl } from '~/core/utils/navigate-to-current-url';
import { UserFeatureFlags } from '~interfaces/user';
import { AuthApiService } from './session.api.service';
import { ClientSession } from './session.type';

@Injectable({ providedIn: 'root' })
export class SessionService {
	private readonly router = inject(Router);
	private readonly authApi = inject(AuthApiService);

	private readonly session$$ = new BehaviorSubject<ClientSession | null>(null);
	public readonly session$ = this.session$$.asObservable();
	public get currentSession(): ClientSession | null {
		return this.session$$.getValue();
	}

	public readonly $session = toSignal(this.session$);

	public readonly $sessionId = computed(
		() => {
			const session = this.$session();
			return session?.userId;
		},
		{ equal: (a, b) => a === b }
	);

	private _pinging = false;
	private _loggingIn = false;
	private _loggingOut = false;

	public async ping(navigate = true): Promise<void> {
		if (this._pinging) {
			return;
		}

		try {
			this._pinging = true;
			const session = await this.authApi.ping();
			this.session$$.next(session);
			if (navigate) {
				this.redirectToSameRoute();
			}
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			this._pinging = false;
		}
	}

	public async login(username: string, password: string): Promise<void> {
		if (this._loggingIn) {
			return;
		}

		try {
			this._loggingIn = true;
			const session = await this.authApi.login(username, password);
			this.session$$.next(session);
			this.redirectToSameRoute();
		} finally {
			this._loggingIn = false;
		}
	}

	public async logout(): Promise<void> {
		if (this._loggingOut) {
			return;
		}

		try {
			this._loggingOut = true;
			await this.authApi.logout();
			this.session$$.next(null);
			this.redirectToAuth();
		} catch (error) {
			console.error(error);
			this.session$$.next(null);
		} finally {
			this._loggingOut = false;
		}
	}

	public hasFeatureFlag(flag: UserFeatureFlags): boolean {
		return this.session$$.value?.featureFlags.includes(flag) ?? false;
	}

	private redirectToAuth(): Promise<boolean> {
		return this.router.navigate(['/'], { onSameUrlNavigation: 'reload' });
	}

	private redirectToSameRoute(): Promise<boolean> {
		return navigateToCurrentUrl(this.router);
	}
}
