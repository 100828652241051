import { UserInPing } from '~interfaces/user';
import { Injectable } from '@angular/core';
import { BaseApiService } from '~/core/services/api/base.api.service';
import { ClientSession } from './session.type';

@Injectable({ providedIn: 'root' })
export class AuthApiService extends BaseApiService {
	protected readonly commonPath = ['auth'];

	public async ping(): Promise<ClientSession> {
		return this.http.get<UserInPing>(this.url('ping')).then(parseSessionFromServer);
	}

	public async login(username: string, password: string): Promise<ClientSession> {
		const body = {
			username,
			password,
		};

		return this.http.post<UserInPing>(this.url('login'), body).then(parseSessionFromServer);
	}

	public async logout(): Promise<void> {
		await this.http.post(this.url('logout'), {});
	}
}

const parseSessionFromServer = (user: UserInPing): ClientSession => {
	return {
		userId: user.privateId,
		email: user.email,
		featureFlags: user.userFeatureFlags,
		adminRole: user.adminFeatureFlags,
	};
};
