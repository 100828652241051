import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { EmptyPageComponent, EmptyWithOutletPageComponent } from '~/core/pages';
import { InitializationPageComponent } from '~/initialization/initialization.page.component';
import { InitializationService } from '~/initialization/initialization.service';
import { SessionService } from '~/session/session.service';
import { AdminFeatureFlags } from '~interfaces/user';

export const rootRoutes: Routes = [
	{
		path: '',
		component: InitializationPageComponent,
		children: [
			{
				// This is a workaround so the app loads blazing fast while we load
				// whatever we need for the app to work.
				path: '',
				component: EmptyWithOutletPageComponent,
				canMatch: [
					() => {
						const appInitialization = inject(InitializationService);
						return appInitialization.initialized === false;
					},
				],
				children: [
					{
						path: '**',
						component: EmptyPageComponent,
					},
				],
			},
			{
				path: '',
				component: EmptyWithOutletPageComponent,
				children: [
					{
						// LOGIN
						path: '',
						canMatch: [
							() => {
								const sessionService = inject(SessionService);
								const session = sessionService.currentSession;
								const canAccessAuth = session === null;
								return canAccessAuth;
							},
						],
						component: EmptyWithOutletPageComponent,
						children: [
							{
								path: '**',
								loadComponent: async () => {
									const m = await import('~/auth/auth.page.component');
									return m.AuthPageComponent;
								},
							},
						],
					},
					{
						// HOME
						path: '',
						canMatch: [
							() => {
								const sessionService = inject(SessionService);
								const session = sessionService.currentSession;
								const canAccessHome = !!session;
								return canAccessHome;
							},
						],
						children: [
							{
								path: '',
								loadChildren: async () => {
									const m = await import('~/home/home.router.module');
									return m.HomeRouterModule;
								},
							},
							{
								// Fail-safe
								path: '**',
								redirectTo: '',
							},
						],
					},
					{
						// NO ACCESS
						path: '**',
						loadComponent: async () => {
							const m = await import('~/no-access/no-access.page.component');
							return m.NoAccessPageComponent;
						},
					},
				],
			},
		],
	},
];
