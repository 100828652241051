import { DOCUMENT } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { provideMarkdown } from 'ngx-markdown';
import { BUILD_TIME, CLIENT_ENVIRONMENT, ReachVersion, REACH_VERSION } from '~/core/tokens';
import { ClientEnvironmentName } from '~/core/types/environment';
import { rootRoutes } from '~/root/root.router';
import { environment } from '~env';
import { provideHotToastConfig } from '@ngneat/hot-toast';

declare const PACKAGE_VERSION: ReachVersion;
declare const COMMIT_HASH: string;
declare const WEBPACK_BUILD_TIME: number;

const getVersion = () => {
	// If we have hash and is not production, show commit hash
	if (!!COMMIT_HASH && environment.environmentName !== ClientEnvironmentName.PRODUCTION) {
		return `${PACKAGE_VERSION}.${COMMIT_HASH}`;
	}

	return `${PACKAGE_VERSION}`;
};

const provideClientTheme = (): Provider => {
	return {
		provide: APP_INITIALIZER,
		useFactory: (document: Document) => {
			return () => {
				const rootElement = document.documentElement;

				const setClientTheme = () => {
					let darkMode = false;
					if (
						window.matchMedia &&
						window.matchMedia('(prefers-color-scheme: dark)').matches
					) {
						darkMode = true;
					}

					rootElement.setAttribute('client-theme', darkMode ? 'dark' : 'light');
				};

				setClientTheme();

				window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
					setClientTheme();
				});
			};
		},
		multi: true,
		deps: [DOCUMENT],
	};
};

export const getRootProviders = () => {
	const appVersion = getVersion();

	const properties = {
		packageVersion: PACKAGE_VERSION,
		commitHash: COMMIT_HASH,
		buildTime: WEBPACK_BUILD_TIME,
		envName: environment.environmentName,
		reachVersion: appVersion,
	};

	if (environment.environmentName !== ClientEnvironmentName.PRODUCTION) {
		console.log(properties);
		console.log(environment);
	}

	return [
		provideRouter(rootRoutes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
		provideClientTheme(),
		provideAnimations(),
		provideHttpClient(),
		provideMarkdown(),
		provideHotToastConfig(),
		{ provide: BUILD_TIME, useValue: BUILD_TIME },
		{ provide: REACH_VERSION, useValue: appVersion },
		{ provide: CLIENT_ENVIRONMENT, useValue: environment },
	];
};
