import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RootPageComponent } from '~/root/root.page';
import { getRootProviders } from '~/root/root.providers';
import { ClientEnvironmentName } from '~/core/types/environment';
import { environment } from './environments/environment';

try {
	if (environment.environmentName === ClientEnvironmentName.PRODUCTION) {
		enableProdMode();
	}

	bootstrapApplication(RootPageComponent, {
		providers: getRootProviders(),
	});
} catch (error) {
	console.error(error);
}
