export enum ClientEnvironmentName {
	TEST = 'TEST',
	PRODUCTION = 'PRODUCTION',
	REPLICA = 'REPLICA',
	STAGING = 'STAGING',
	DEVELOPMENT = 'DEVELOPMENT',
	LOCAL = 'LOCAL',
}

export interface ClientEnvironment {
	environmentName: ClientEnvironmentName;

	/** server is `api.rea.ch` */
	server: {
		url: string;
	};

	/** mainClient is `mobile.rea.ch` */
	mainClient: {
		url: string;
	};

	/** handbook is `handbook.rea.ch` */
	handbook: {
		url: string;
	};

	/**
	 * Also known as `widget`
	 */
	myGenieServer: {
		url: string;
	};
}
