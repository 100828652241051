import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';
import { SessionService } from '~/session/session.service';
import { SettingsApiService } from './settings.api.service';
import { ClientSettings, DEFAULT_CLIENT_SETTINGS } from './settings.type';

@Injectable({ providedIn: 'root' })
export class SettingsService {
	private readonly sessionService = inject(SessionService);
	private readonly settingsApi = inject(SettingsApiService);

	private readonly loadingSettings$$ = new BehaviorSubject<boolean>(false);
	public readonly loadingSettings$ = this.loadingSettings$$.asObservable();

	private readonly settings$$ = new BehaviorSubject<ClientSettings>(DEFAULT_CLIENT_SETTINGS);
	public readonly settings$ = this.settings$$.asObservable();

	private loadReqId = 0;
	private autoLoadSettings = true;

	constructor() {
		this.sessionService.session$
			.pipe(
				map((session) => session?.userId),
				distinctUntilChanged((prev, curr) => {
					return prev === curr;
				})
			)
			.subscribe(async (userId) => {
				if (this.autoLoadSettings) {
					if (userId) {
						try {
							await this.load();
						} catch (error) {}
					} else {
						this.reset();
					}
				}
			});
	}

	public setAutoLoadSettings(autoLoad: boolean): void {
		this.autoLoadSettings = autoLoad;
	}

	public async load(): Promise<void> {
		const reqId = ++this.loadReqId;

		try {
			this.loadingSettings$$.next(true);
			const response = await this.settingsApi.get();

			if (reqId !== this.loadReqId) {
				return;
			}

			this.settings$$.next(response);
		} catch (error) {
			console.error(error);
			this.settings$$.next(DEFAULT_CLIENT_SETTINGS);
		} finally {
			if (reqId === this.loadReqId) {
				this.loadingSettings$$.next(false);
			}
		}
	}

	private reset(): void {
		this.loadReqId++;
		this.loadingSettings$$.next(false);
		this.settings$$.next(DEFAULT_CLIENT_SETTINGS);
	}
}
