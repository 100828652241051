<ng-container
	*ngIf="{
		fontsLoaded: fontsLoaded$ | async,
		sessionLoaded: sessionLoaded$ | async,
		loaded: loaded$ | async,
	} as vm"
>
	<div class="w-100 h-100 fc-800" [class.bg-190]="vm.loaded" style="transition: background 300ms">
		<router-outlet *ngIf="vm.loaded"></router-outlet>
	</div>

	<div *ngIf="!vm.loaded" class="pos-abs pos-t-0 posl-0 w-100 h-100 fl fl-ai-c fl-jc-c">
		<i
			class="icon-loading-bubbles"
			style="font-size: 100px; transition: opacity 300ms"
			[style.opacity]="vm.fontsLoaded ? '1' : '0'"
		></i>
	</div>
</ng-container>
