import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { distinctUntilChanged } from 'rxjs';
import { SettingsService } from './settings.service';
import { ClientSettings } from './settings.type';

const SETTINGS_TO_REACT: Partial<{ [key in keyof ClientSettings]: string }> = {
	theme: 'app-theme',
};

const SETTINGS_KEYS_TO_REACT = Object.keys(SETTINGS_TO_REACT) as (keyof ClientSettings)[];

@Component({
	selector: 'app-settings-reactor',
	template: '',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [],
	host: {
		class: 'dis-none',
	},
})
export class SettingsReactorComponent {
	private readonly document = inject(DOCUMENT);
	private readonly settingsService = inject(SettingsService);

	private readonly rootElement = this.document.documentElement;

	constructor() {
		this.settingsService.settings$
			.pipe(
				distinctUntilChanged((prev, curr) => {
					return !SETTINGS_KEYS_TO_REACT.some((key) => prev[key] !== curr[key]);
				})
			)
			.subscribe((settings) => {
				this.reactToSettings(settings);
			});
	}

	private reactToSettings(settings: ClientSettings): void {
		for (const _key in SETTINGS_TO_REACT) {
			const key: keyof ClientSettings = _key as keyof ClientSettings;
			const name = SETTINGS_TO_REACT[key] ?? '';
			const value = settings[key];

			if (name && value) {
				this.rootElement.setAttribute(name, value);
			}
		}
	}
}
