import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import * as FontFaceObserver from 'fontfaceobserver';
import { BehaviorSubject } from 'rxjs';
import { SessionService } from '~/session/session.service';
import { SettingsService } from '~/settings/settings.service';
import { InitializationService } from './initialization.service';

const FONTS_TO_CHECK = ['Roboto', 'icomoon'];

@Component({
	selector: 'app-initialization',
	templateUrl: './initialization.page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, RouterModule],
	host: {
		class: 'block w-100 h-100',
	},
})
export class InitializationPageComponent implements OnInit {
	private readonly router = inject(Router);
	private readonly service = inject(InitializationService);
	private readonly sessionService = inject(SessionService);
	private readonly settingsService = inject(SettingsService);

	private readonly fontsLoaded$$ = new BehaviorSubject<boolean>(false);
	public readonly fontsLoaded$ = this.fontsLoaded$$.asObservable();

	private readonly sessionLoaded$$ = new BehaviorSubject<boolean>(false);
	public readonly sessionLoaded$ = this.sessionLoaded$$.asObservable();

	private readonly loaded$$ = new BehaviorSubject<boolean>(false);
	public readonly loaded$ = this.loaded$$.asObservable();

	async ngOnInit(): Promise<void> {
		try {
			await Promise.allSettled([this.loadSession(), this.loadFonts()]);
		} catch (error) {
		} finally {
			this.service.setState(true);
			this.loaded$$.next(true);
			// This redirects the app to the same url that was called.
			// This is a hack used to gain initial load time.
			this.router.navigateByUrl(this.router.url, {
				skipLocationChange: false,
				replaceUrl: true,
				onSameUrlNavigation: 'reload',
			});
		}
	}

	private async loadSession(): Promise<void> {
		this.settingsService.setAutoLoadSettings(false);
		try {
			await new Promise((res) => setTimeout(res, 1_000));
			await this.sessionService.ping();
			await this.settingsService.load();
		} catch (error) {}
		this.settingsService.setAutoLoadSettings(true);
		this.sessionLoaded$$.next(true);
	}

	private async loadFonts(): Promise<void> {
		const fontPromises = FONTS_TO_CHECK.map((font) => {
			const checkFont = new FontFaceObserver(font);
			return checkFont.load();
		});

		await Promise.allSettled(fontPromises);
		this.fontsLoaded$$.next(true);
	}
}
